import {useState} from "react";

const checkIsInstalled = (): boolean => {
  // @ts-ignore
  if (navigator.standalone) {
    return true;
  }

  return window.matchMedia('(display-mode: standalone)').matches;
};

const useIsInstalled = () => {
  return useState<boolean>(checkIsInstalled());
};

export default useIsInstalled;
