import React from 'react';
import route from "ziggy-js";
import {
  LineChartIcon,
  CrownIcon,
  LandmarkIcon,
  LogOutIcon,
  MessageCircleIcon,
  MessagesSquareIcon,
  UserCogIcon,
  GoalIcon,
  BarChartHorizontalIcon,
  FacebookIcon,
  SquirrelIcon
  , BanknoteIcon,
} from "lucide-react";
import CardSwipeIcon from "@/Components/icons/CardSwipeIcon";

export default {
  "mobile": [
    {
      "type": "link",
      "title": "Home",
      "icon": <SquirrelIcon/>,
      "link": route("home", [], false)
    },
    {
      "type": "link",
      "title": "Swipe",
      "icon": <CardSwipeIcon/>,
      "link": route("swipe.index", [], false)
    },
    {
      "type": "link",
      "title": "Save",
      "icon": <BanknoteIcon/>,
      "link": route("save.index", [], false)
    },
  ],
  "mobileExpanded": [
    {
      "type": "external-link",
      "title": "Join the Community",
      "icon": <FacebookIcon/>,
      "link": "https://www.facebook.com/groups/rulemoneyuk",
    },
    // {
    //   "type": "link",
    //   "title": "Rules",
    //   "icon": <CrownIcon/>,
    //   "link": route("your-rule", [], false)
    // },
    // {
    //   "type": "link",
    //   "title": "Audit",
    //   "icon": <BarChartHorizontalIcon/>,
    //   "link": route("audit.detailed", [], false)
    // },
    // {
    //   "type": "link",
    //   "title": "Check-in",
    //   "icon": <MessageCircleIcon/>,
    //   "link": route("check-in", [], false)
    // },
    {
      "type": "link",
      "title": "Connected Banks",
      "icon": <LandmarkIcon/>,
      "link": route("open-banking.manage", [], false)
    },
    {
      "type": "link",
      "title": "Account Settings",
      "icon": <UserCogIcon/>,
      "link": route("profile.show", [], false)
    },
    {
      "type": "link",
      "title": "Support Chat",
      "icon": <MessagesSquareIcon/>,
      "link": route("live-chat", [], false)
    },
  ],
  "desktopLeft": [
    {
      "type": "link",
      "title": "Home",
      "icon": <SquirrelIcon/>,
      "link": route("home", [], false)
    },
    {
      "type": "link",
      "title": "Swipe",
      "icon": <CardSwipeIcon/>,
      "link": route("swipe.index", [], false)
    },
    {
      "type": "link",
      "title": "Save",
      "icon": <BanknoteIcon/>,
      "link": route("save.index", [], false)
    },
  ],
  "desktopRight": [
    {
      "type": "dropdown",
      "icon": <UserCogIcon/>,
      "items": [
        // {
        //   "type": "link",
        //   "title": "Rules",
        //   "icon": <CrownIcon/>,
        //   "link": route("your-rule", [], false)
        // },
        // {
        //   "type": "link",
        //   "title": "Audit",
        //   "icon": <BarChartHorizontalIcon/>,
        //   "link": route("audit.detailed", [], false)
        // },
        // {
        //   "type": "link",
        //   "title": "Check-in",
        //   "icon": <MessageCircleIcon/>,
        //   "link": route("check-in", [], false)
        // },
        {
          "type": "link",
          "title": "Connected Banks",
          "icon": <LandmarkIcon/>,
          "link": route("open-banking.manage", [], false)
        },
        {
          "type": "link",
          "title": "Account Settings",
          "icon": <UserCogIcon/>,
          "link": route("profile.show", [], false)
        },
        {
          "type": "link",
          "title": "Support Chat",
          "icon": <MessagesSquareIcon/>,
          "link": route("live-chat", [], false)
        },
        {
          "type": "logout",
          "title": "Log Out",
          "icon": <LogOutIcon/>,
        }
      ]
    }
  ]
};
