import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "@/Utils"
import { Loader2Icon } from "lucide-react";

const buttonVariants = cva(
  [
    "inline-flex items-center justify-center ring-offset-background",
    "transition-all duration-100 ease-in",
    "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
    "disabled:pointer-events-none disabled:opacity-50",
    "text-center",
  ],
  {
    variants: {
      variant: {
        primary: "font-display font-medium bg-primary shadow-primary-shadow active:shadow-primary-shadow text-primary-foreground hover:bg-primary/90",
        secondary: "font-display font-medium bg-secondary shadow-secondary-shadow active:shadow-secondary-shadow text-secondary-foreground hover:bg-secondary/90",
        nav: "font-sans font-medium bg-muted shadow-stone-300 active:shadow-stone-300 text-muted-foreground hover:bg-muted/90",
        destructive: "font-display font-medium bg-destructive shadow-destructive-shadow active:shadow-destructive-shadow text-destructive-foreground hover:bg-destructive/90",
        link: "font-sans text-primary shadow-none hover:text-underline active:text-blue-600/90",
      },
      size: {
        sm: "h-8 px-3 py-1 text-sm leading-none font-normal rounded-lg",
        default: "h-10 px-5 py-2 text-base leading-none rounded-xl",
        lg: "h-11 px-8 py-4 text-lg leading-none rounded-2xl",
        icon: "h-10 aspect-square p-2 rounded-xl",
      },
      chunky: {
        off: "shadow-none",
        sm: "shadow-[0_2px_0_0] active:shadow-none active:translate-y-[1px]",
        default: "shadow-[0_3px_0_0] active:shadow-[0_1px_0_0] active:translate-y-[2px]",
        lg: "shadow-[0_4px_0_0] active:shadow-[0_1px_0_0] active:translate-y-[3px]",
      },
      wide: {
        true: "w-full",
        false: "w-fit",
      }
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
      chunky: "default",
      wide: false,
    },
  }
)

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean
  loading?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, chunky, wide, loading, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, chunky, wide, className }))}
        ref={ref}
        {...props}
      >
        {loading ? <Loader2Icon className="animate-spin w-4 h-4"/> : props.children}
      </Comp>
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
