import React, { useEffect } from 'react';
import useGTM from '@/Hooks/useGTM';
import {router} from "@inertiajs/core";
import {sendToGTM} from "@elgorditosalsero/react-gtm-hook";
import useTypedPage from "@/Hooks/useTypedPage";
import {Auth, User} from "@/types";

export default function SendServerEventsToGTM({children}) {
  const { gtm_events } = useTypedPage().props;
  const sendGTMEvent = useGTM();
  let user: User|null = null;

  // Server sent events
  useEffect(() => {
    if (gtm_events && gtm_events.length > 0) {
      gtm_events.forEach((eventName) => {
        sendGTMEvent({ event: eventName });
      });
    }
  }, [gtm_events, sendGTMEvent]);

  // Inertia navigation
  useEffect(() => {
    return router.on('navigate', (event) => {
      // Update email
      const auth = event.detail.page.props.auth as Auth;
      const newUser = auth?.user;
      if (user?.id !== newUser?.id) { // Logged in
        sendToGTM({ dataLayerName: 'dataLayer', data: { email: auth.user?.email }});
        user = newUser;
      }

      // Pageview
      sendGTMEvent({ event: 'pageview' });
    });
  }, []);

  return <>{children}</>;
};
