import React, {useState} from 'react';
import {Sheet, SheetContent, SheetHeader, SheetTitle} from "@/Components/ui/sheet";
import usePushNotifications from "@/Hooks/usePushNotification";
import {useLocalStorage} from "usehooks-ts";
import dayjs from "dayjs";
import {Button} from "@/Components/ui/button";
import {BellDotIcon} from "lucide-react";
import {cn} from "@/Utils";
import useGTM from "@/Hooks/useGTM";

const DAYS_TO_RESHOW = 14;

export default function EnableNotificationsPrompt() {
  const {
    pushNotificationSupported,
    subscribe,
    loading,
    userConsent,
    localSubscription,
  } = usePushNotifications();
  const [isOpen, setIsOpen] = useState(pushNotificationSupported);
  const [notificationDismissedAt, setNotificationDismissedAt] = useLocalStorage<string|null>('notificationDismissedAt', null);
  const sendGTMEvent = useGTM();

  if (!pushNotificationSupported) {
    return (<></>);
  }

  if (dayjs().diff(notificationDismissedAt, 'days') < DAYS_TO_RESHOW) {
    return (<></>);
  }

  if (userConsent === "denied") {
    return (<></>);
  }

  if (localSubscription !== null) {
    return (<></>);
  }

  async function handleSubscribe() {
    const successfullySubscribed = await subscribe();
    if (successfullySubscribed) {
      sendGTMEvent({event: 'push_notifications_subscribed'});
      setIsOpen(false);
    }
  }

  function dismiss() {
    sendGTMEvent({ event: 'push_notifications_dismissed' });
    setNotificationDismissedAt(dayjs().toISOString());
    setIsOpen(false);
  }

  return (
    <Sheet
      open={isOpen}
      modal={true}
      onOpenChange={() => {
        setIsOpen(false)
      }}
    >
      <SheetContent
        side="bottom"
        className="max-w-lg sm:ml-12"
      >
        <SheetHeader className="h-full">
          <SheetTitle className="font-sans">Enable Notifications</SheetTitle>
        </SheetHeader>

        <div className="overflow-y-auto h-full flex flex-col gap-y-4 text-sm">
          <p>Gentle nudges make us 50% more likely to stick to our habits.</p>
          <div>
            <p>Receive notifications when you:</p>
            <ul className="mt-2 list-disc ml-6 text-muted-foreground leading-tight flex flex-col gap-y-1">
              <li>Reach a milestone towards your goal</li>
              <li>Earn a new credit</li>
              <li>Make a purchase that matches your habit</li>
            </ul>
          </div>

          <img
            src="/images/notification-takeaways-credit.png"
            alt="A smartphone notification showing progress made towards a savings goal"
            className="w-full h-auto px-4"
            width={664}
            height={245}
          />
        </div>

        <div className="mt-6">
          <Button
            onClick={handleSubscribe}
            variant="primary"
            size="default"
            wide={true}
            className={cn({'opacity-50 cursor-not-allowed': loading})}
            disabled={loading}
          >
            <BellDotIcon className="w-6 h-6 mr-2"/>
            Subscribe to notifications
          </Button>

          <Button
            onClick={dismiss}
            variant="link"
            chunky="off"
            size="default"
            wide={true}
            className={cn({'opacity-50 cursor-not-allowed': loading})}
            disabled={loading}
          >
            Don't ask again
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  )
}
