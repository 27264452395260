const containerId = import.meta.env.VITE_GTM_CONTAINER_ID;
const auth = import.meta.env.VITE_GTM_AUTH;
const preview = import.meta.env.VITE_GTM_PREVIEW;

const gtmConfig = new Map();
gtmConfig.set('id', containerId);

if (typeof auth !== 'undefined' && typeof preview !== 'undefined') {
  gtmConfig.set('environment', {
    gtm_auth: auth,
    gtm_preview: preview,
  });
}

export {gtmConfig};
