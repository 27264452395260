import {useGTMDispatch} from "@elgorditosalsero/react-gtm-hook";

const useGTM = () => {
  let sendGTMEvent = (props: object) => console.log("GTM disabled", props);

  if (import.meta.env.VITE_GTM_CONTAINER_ID) {
    sendGTMEvent = useGTMDispatch();
  }

  return sendGTMEvent;
}

export default useGTM;
