export const rollbarConfig = {
  accessToken: import.meta.env.VITE_ROLLBAR_POST_CLIENT_ITEM_KEY,
  environment: import.meta.env.VITE_ROLLBAR_ENVIRONMENT,
  captureUncaught: true,
  captureUnhandledRejections: true,
  level: 'warning',
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: import.meta.env.VITE_GITHUB_SHA,
      }
    }
  }
};
