import {provider} from "@/Providers/ProviderComposer";
import {RouteContext} from "@/Hooks/useRoute";
import {ErrorBoundary, Provider as RollbarProvider} from "@rollbar/react";
import {rollbarConfig} from "@/rollbar";
import PostHogEventProvider from "@/Providers/PostHogEventProvider";
import {GTMProvider} from "@elgorditosalsero/react-gtm-hook";
import {gtmConfig} from "@/gtm";

const providers = [
  provider(RouteContext.Provider, {value: (window as any).route}),
];

if (import.meta.env.PROD) {
  providers.push(...[
    provider(RollbarProvider, {config: rollbarConfig}),
    provider(ErrorBoundary),
    provider(PostHogEventProvider),
  ]);
}

if (!!import.meta.env.VITE_GTM_CONTAINER_ID) {
  providers.push(...[
    provider(GTMProvider, {state: Object.fromEntries(gtmConfig)}),
  ]);
}

export default providers;
