import React, {PropsWithChildren, useEffect} from 'react';
import posthog from "posthog-js";
import {PostHogProvider} from "posthog-js/react";
import {router} from "@inertiajs/core";
import {Auth, User} from "@/types";
import useIsInstalled from "@/Hooks/useIsInstalled";

const options = {
  api_host: 'https://eu.posthog.com',
  capture_pageview: false,
  capture_pageleave: false,
  autocapture: true,
};

posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, options);
window.posthog = posthog;

export default function PostHogEventProvider({ children }: PropsWithChildren) {
  const [isInstalled] = useIsInstalled();

  let user: User|null = null;
  let lastLocation: Location|null = null;

  function onNavigate(event) {
    // Identify user
    const auth = event.detail.page.props.auth as Auth;
    const newUser = auth?.user;
    if (!!user?.id && newUser === null) {
      posthog?.reset();
      user = null;
    } else if (user?.id !== newUser?.id) {
      posthog?.identify(newUser?.id.toString(), {...newUser});
      user = newUser;
    }

    // Update person properties
    posthog?.capture('$set', {
      $set: { pwa_installed: isInstalled },
    })

    // Leaving a page
    const newPath = event.detail.page.url;
    if (lastLocation !== null && lastLocation.pathname !== newPath) {
      posthog?.capture('$pageleave', {
        $current_url: lastLocation.href,
        $host: lastLocation.host,
        $pathname: lastLocation.pathname,
      });
    }
    lastLocation = {...window.location};

    // Entering a page
    posthog?.capture('$pageview');
  }

  useEffect(() => {
    return router.on('navigate', onNavigate);
  }, []);

  return (
    <PostHogProvider client={posthog}>
      {children}
    </PostHogProvider>
  );
}
