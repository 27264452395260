import React, {useRef, useState} from 'react';
import useRoute from '@/Hooks/useRoute';
import useTypedPage from '@/Hooks/useTypedPage';
import {Link} from '@inertiajs/react';
import {router} from '@inertiajs/core';
import {useEventListener, useOnClickOutside} from "usehooks-ts";
import {
  ArrowDownCircleIcon,
  ExternalLinkIcon,
  MoreHorizontalIcon,
} from "lucide-react";
import {cn} from "@/Utils";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuViewport,
  navigationMenuTriggerStyle,
} from '@/Components/ui/navigation-menu';
import {Separator} from "@/Components/ui/separator";
import structure from "@/Layouts/Partials/MainNavStructure";
import useIsInstalled from "@/Hooks/useIsInstalled";
import {Drawer, DrawerContent, DrawerTrigger} from "@/Components/ui/drawer";

export default function MainNav() {
  const route = useRoute();
  const {url, props} = useTypedPage();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isInstalled] = useIsInstalled();
  const drawer = useRef(null);

  function toggleExpandedMobileList() {
    setIsExpanded(!isExpanded);
  }

  function logout(e: React.FormEvent) {
    e.preventDefault();
    if (!props.auth?.user?.shadow || confirm('You haven\'t set an email or password yet. If you logout you won\'t be able to log back in. Are you sure you want to logout?')) {
      router.post(route('logout'));
    }
  }

  useEventListener('keyup', (e: KeyboardEvent) => {
    if (e.key === 'Escape' && isExpanded) {
      setIsExpanded(false);
    }
  });

  useEventListener('popstate', (e: PopStateEvent) => {
    if (isExpanded) {
      e.preventDefault();
      history.go(1);
      setIsExpanded(false);
    }
  });

  useOnClickOutside(drawer, () => {
    if (isExpanded) {
      setIsExpanded(false);
    }
  })

  const Mobile = ({children}) => (
    <div className={cn(
      'fixed block sm:hidden bottom-0 z-50',
      'w-full pb-safe',
      'bg-white text-sm',
    )}>
      <div className="container">{children}</div>
    </div>
  );

  const Desktop = ({children}) => (
    <div className={cn(
      'sticky hidden sm:block top-0 z-50',
      'w-full py-3',
      'bg-white text-sm',
    )}>
      <div className="container">{children}</div>
    </div>
  );

  const LoggedOut = ({className}) => (
    <div className={cn(className, "w-full flex gap-4 justify-between items-center")}>
      <div className="flex gap-4 items-center">
        <Link href={route('welcome')} className="text-link">
          Register for Rule
        </Link>
        <Link href={route('choose-habit')} className="text-link">
          Habit calculator
        </Link>
      </div>
      <div>
        Already a member?{' '}
        <Link href={route('login')} className="text-link">
          Log in
        </Link>
      </div>
    </div>
  );

  const LoggedInMobile = () => {
    // Swap last of the 4 menu items for the install button if not installed
    let mobileStructure = structure.mobile;
    if (!isInstalled) {
      mobileStructure = [
        ...structure.mobile,
        {
          type: 'link',
          title: 'Install',
          icon: <ArrowDownCircleIcon/>,
          link: route('install', [], false),
        },
      ];
    }

    return (
      <NavigationMenu className="w-full block">
        <NavigationMenuList className="justify-around space-x-0">
          {mobileStructure.filter(item => item.type === 'link').map((item, idx) => (
            <NavigationMenuItem key={idx}>
              <Link
                href={item.link}
                data-active={url.startsWith(item.link)}
                className={cn(
                  'flex-1 flex flex-col gap-y-[6px] items-center w-full p-2',
                  '[&>*]:transition [&>*]:duration-150 [&>*]:ease-in-out',
                  '[&>*]:whitespace-nowrap [&>*]:text-black/50 [&>*]:hover:text-black',
                  '[&>svg]:rounded [&>svg]:w-auto [&>svg]:h-6 [&>svg]:overflow-visible',
                  url.startsWith(item.link) && '[&>*]:text-black',
                )}
              >
                {item.icon}
                <span className="text-xs leading-none text-center">{item.title}</span>
              </Link>
            </NavigationMenuItem>
          ))}
          <NavigationMenuItem className="flex-0">
            <DrawerTrigger
              onClick={toggleExpandedMobileList}
              asChild
            >
              <button className="flex-1 flex flex-col gap-y-2 items-center w-full p-4">
                <MoreHorizontalIcon className="w-auto h-6 text-muted-foreground"/>
              </button>
            </DrawerTrigger>
          </NavigationMenuItem>
        </NavigationMenuList>
      </NavigationMenu>
    );
  }

  const MobileExpandedList = () => {
    let mobileStructureExpanded = structure.mobileExpanded;

    return (
      <DrawerContent ref={drawer} className="shadow-t-lg">
        <div className="container pt-6 pb-4 mx-auto w-full max-w-sm">
          <NavigationMenu className="w-full block">
            <NavigationMenuList className="w-full flex flex-col gap-y-2">
              {transformStructureToExpandedMobileComponents(mobileStructureExpanded)}
            </NavigationMenuList>
          </NavigationMenu>
        </div>
      </DrawerContent>
    );
  }

  const MobileExpandedLink = ({item}) => (
    <Link
      href={item.link}
      onClick={toggleExpandedMobileList}
      data-active={url.startsWith(item.link)}
      className={cn(
        'w-full p-3 flex items-center gap-x-2 bg-card rounded-md',
        'transition duration-150 ease-in-out',
        '[&>*]:whitespace-nowrap [&>*]:hover:text-black [&>svg]:rounded [&>svg]:w-5 [&>svg]:h-5 [&>svg]:overflow-visible',
        url.startsWith(item.link) && 'bg-primary/20',
      )}
    >
      {item.icon}
      <span className="leading-none">{item.title}</span>
    </Link>
  )

  const MobileExpandedExternalLink = ({item}) => (
    <a
      href={item.link}
      onClick={toggleExpandedMobileList}
      target="_blank"
      className={cn(
        'w-full p-3 flex items-center gap-x-2 bg-card rounded-md',
        'transition duration-150 ease-in-out',
        '[&>*]:whitespace-nowrap [&>*]:hover:text-black [&>svg]:rounded [&>svg]:w-5 [&>svg]:h-5 [&>svg]:overflow-visible',
      )}
    >
      {item.icon}
      <span className="leading-none">{item.title}</span>
      <ExternalLinkIcon strokeWidth={1}/>
    </a>
  )

  const DesktopLink = ({item}) => (
    <Link
      href={item.link}
      data-active={url.startsWith(item.link)}
      className={cn(
        navigationMenuTriggerStyle(),
        'flex items-center justify-start gap-x-2 w-full',
        'transition duration-150 ease-in-out',
        '[&>svg]:text-muted-foreground [&>svg]:hover:text-black [&>svg]:w-5 [&>svg]:h-5',
      )}
    >
      {item.icon}
      <span>{item.title}</span>
    </Link>
  )

  const DesktopLogout = ({item}) => (
    <div>
      <Separator className="my-2"/>
      <form onSubmit={logout}>
        <button
          type="submit"
          className={cn(
            navigationMenuTriggerStyle(),
            'flex items-center justify-start gap-x-2 w-full',
            'transition duration-150 ease-in-out',
            '[&>svg]:text-muted-foreground [&>svg]:hover:text-black [&>svg]:w-5 [&>svg]:h-5',
          )}
        >
          {item.icon}
          <span>{item.title}</span>
        </button>
      </form>
    </div>
  )

  const DesktopDropdown = ({item}) => (
    <>
      <NavigationMenuTrigger
        className={cn(
          navigationMenuTriggerStyle(),
          'flex items-center justify-start gap-x-2 w-full',
          'transition duration-150 ease-in-out',
          '[&>svg]:text-muted-foreground [&>svg]:hover:text-black [&>svg]:w-5 [&>svg]:h-5',
        )}
      >
        {item.icon}
        {item.title && <span>{item.title}</span>}
      </NavigationMenuTrigger>
      <NavigationMenuContent>
        <ul className="grid w-[230px] gap-y-1 p-4">
          {transformStructureToComponents(item.items)}
        </ul>
      </NavigationMenuContent>
    </>
  );

  const transformStructureToComponents = (items) => {
    return items.map((item, idx) => {
      let NavComponent;
      switch (item.type) {
        case 'link':
          NavComponent = DesktopLink;
          break;
        case 'dropdown':
          NavComponent = DesktopDropdown;
          break;
        case 'logout':
          NavComponent = DesktopLogout;
          break;
      }
      return (
        <NavigationMenuItem key={idx}>
          <NavComponent item={item}/>
        </NavigationMenuItem>
      );
    });
  }

  const transformStructureToExpandedMobileComponents = (items) => {
    return items.map((item, idx) => {
      let NavComponent;
      switch (item.type) {
        case 'link':
          NavComponent = MobileExpandedLink;
          break;
        case 'external-link':
          NavComponent = MobileExpandedExternalLink;
          break;
      }
      return (
        <NavigationMenuItem className="w-full" key={idx}>
          <NavComponent item={item}/>
        </NavigationMenuItem>
      );
    });
  }

  const LoggedInDesktop = () => (
    <NavigationMenu className="w-full flex justify-between">
      <NavigationMenuList>
        {transformStructureToComponents(structure.desktopLeft)}
      </NavigationMenuList>
      <NavigationMenuList className="relative">
        {!isInstalled && (
          <DesktopLink item={{
            link: route('install', [], false),
            icon: <ArrowDownCircleIcon/>,
            title: 'Install',
          }}
          />
        )}
        {transformStructureToComponents(structure.desktopRight)}
      </NavigationMenuList>

      <NavigationMenuViewport/>
    </NavigationMenu>
  )

  return (
    <>
      {props.auth.user ? (
        <>
          <Drawer open={isExpanded} modal={true}>
            <Mobile>
              <LoggedInMobile/>
              <MobileExpandedList/>
            </Mobile>
          </Drawer>
          <Desktop>
            <LoggedInDesktop/>
          </Desktop>
        </>
      ) : (
        <>
          <Mobile>
            <LoggedOut className="py-4"/>
          </Mobile>
          <Desktop>
            <LoggedOut/>
          </Desktop>
        </>
      )}
    </>
  );
}
