import React from 'react';
import {Page, router} from '@inertiajs/core';
import {usePage} from '@inertiajs/react';
import axios from 'axios';

interface Modal {
  component: string;
  baseURL: string;
  redirectURL: string | null;
  props: Record<string, any>;
  key: string;
  nonce: string;
}

interface Context {
  show: boolean;
  close: (shouldRedirect: boolean) => void;
  redirect: () => void;
}

const ModalContext = React.createContext({} as Context);

const ModalProvider = ({children, resolve}) => {
  const {props: pageProps} = usePage<Page<{ modal: Modal }>>();

  const modal = React.useMemo(() => pageProps.modal, [pageProps]);
  const props = React.useMemo(() => (modal ? modal.props : {}), [modal]);
  const key = React.useMemo(() => (modal ? modal.key : ''), [modal]);

  const [componentName, setComponentName] = React.useState<null | string>(null);
  const [Component, setComponent] = React.useState<boolean | React.FC>(false);
  const [show, setShow] = React.useState(false);
  const [nonce, setNonce] = React.useState<null | string>(null);

  const setHeaders = React.useCallback((values: Record<string, string | null>) => {
    Object.entries(values).forEach(([key, value]) =>
      ['post', 'put', 'patch', 'delete'].forEach((method) => {
        axios.defaults.headers[method][key] = value;
      })
    );
  }, []);

  const resetHeaders = React.useCallback(() => {
    const headers = ['X-Inertia-Modal-Key', 'X-Inertia-Modal-Redirect'];

    headers.forEach((key) =>
      ['get', 'post', 'put', 'patch', 'delete'].forEach((method) => {
        delete axios.defaults.headers[method][key];
      })
    );
  }, []);

  const updateHeaders = React.useCallback(() => {
    setHeaders({
      'X-Inertia-Modal-Key': key,
      'X-Inertia-Modal-Redirect': modal.redirectURL,
    });

    axios.defaults.headers.get['X-Inertia-Modal-Redirect'] = modal.redirectURL ?? '';
  }, [modal, key, setHeaders]);

  const redirect = React.useCallback(() => {
    const redirectURL = modal.redirectURL ?? modal.baseURL;

    if (!redirectURL) {
      return;
    }

    return router.visit(redirectURL, {
      preserveScroll: true,
    });
  }, [modal]);

  const close = React.useCallback(
    (shouldRedirect = false) => {
      setShow(false);

      resetHeaders();

      if (shouldRedirect) {
        redirect();
      }
    },
    [resetHeaders, redirect]
  );

  const resolveComponent = React.useCallback(() => {
    if (!modal || nonce === modal.nonce) {
      return close();
    }

    if (componentName !== modal.component) {
      setComponentName(modal.component);

      if (modal.component) {
        resolve(modal.component)
          .then((loadedComponent) => {
            setComponent(() => loadedComponent.default);
          })
          .catch((e) => {
            console.log('error loading component:', e);
            setComponent(false);
          });
      } else {
        setComponent(false);
      }
    }

    setNonce(modal.nonce);
    setShow(true);
  }, [nonce, modal, close, componentName, resolve]);

  React.useEffect(() => {
    resolveComponent();

    const handlePopState = () => setNonce(null);

    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', handlePopState);
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  React.useEffect(() => {
    if ((modal && modal.nonce !== nonce) || (nonce && !modal)) {
      resolveComponent();
    }
  }, [modal, resolveComponent, nonce]);

  React.useEffect(() => {
    if (key) {
      updateHeaders();
    }
  }, [key, updateHeaders]);

  return (
    <ModalContext.Provider value={{close, show, redirect}}>
      {children}
      {/* This shows the modal. could also be placed in a seperate component but in my project i just need it at the bottom of the page */}
      {typeof Component !== 'boolean' && show
        ? <Component {...props} />
        : null}
    </ModalContext.Provider>
  );
};

const useModal = (): Context => React.useContext(ModalContext);

export {ModalProvider, useModal};
